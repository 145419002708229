import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { ICaseQuery } from '../../../../queries/cases/types';
import { RestocareTitleScreen } from '../../../../components/directions/restocare/screens/RestocareTitleScreen';
import { RestocareCardsScreen } from '../../../../components/directions/restocare/screens/RestocareCardsScreen';
import { RestocareLaunchScreen } from '../../../../components/directions/restocare/screens/RestocareLaunchScreen';
import { RestocareContactFormScreen } from '../../../../components/directions/restocare/screens/RestocareContactFormScreen';
import { RestocareFooter } from '../../../../components/directions/restocare/screens/RestocareFooter';
import root from '../../../../components/directions/restocare/styles/global.module.scss';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const restocare: FC<IProps> = ({
  pageContext: { locale = 'ru', tag = '', page = 0 },
}) => (
  <ReCaptchaProvider>
    <MainLayout locale={locale} title="IIKO">
      <div className={root.case}>
        <RestocareTitleScreen />
        <RestocareCardsScreen />
        <RestocareLaunchScreen />
        <RestocareContactFormScreen />
        <RestocareFooter />
      </div>
    </MainLayout>
  </ReCaptchaProvider>
);

export default restocare;
